body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
font-family: 'Iranian Sans Regular';
font-style: normal;
font-weight: normal;
src: local('Iranian Sans Regular'), url('/src/static/irsans.woff') format('woff');
}


@font-face {
font-family: 'Iranian Sans Bold';
font-style: normal;
font-weight: normal;
src: local('Iranian Sans Bold'), url('/src/static/irsansb.woff') format('woff');
}